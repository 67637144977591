import React, { useState, useContext } from 'react';
import { formatDateTimeToDefault } from '@optml/alera-share';

// kendo
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { Switch } from '@progress/kendo-react-inputs';

// components
import ScanBarcode from '@/components/common-components/ScanBarcode';
import Badge from '@/components/common-components/Badge';
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import { regularVendors, freeDrugVendors } from '@/common/Vendors';
import {
  InputField,
  DatePickerField,
  DropDownListField
} from '@/common/Validation';

// context
import {
  ProductContext,
  LocationContext,
  UserContext,
  NotifContext
} from '@/context';

// validation
import validator from './AddProductForm.validator';
import { addProductsToInventory } from '@/graphql/mutations';
import { formatDateToAWS } from '@/common/DateHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';

const AddProductForm = () => {
  const [formData, setFormData] = useState({
    isFreeDrug: false,
    notes: '',
    recDate: new Date(),
    expDate: null,
    vendor: null,
    vendorsList: regularVendors,
    drug: null,
    price: 0,
    serial: ''
  });

  const [isFreeDrug, setIsFreeDrug] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { products } = useContext(ProductContext);
  const { agentId } = useContext(UserContext);
  const { showSuccess, showError } = useContext(NotifContext);
  const { currentLocation, getLocationInventory } = useContext(LocationContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the form data for the changed field
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      price: name === 'drug' ? value.price : prevData.drug?.price
    }));
  };

  const handleSwitchChange = () => {
    const newValue = !isFreeDrug;
    setIsFreeDrug(newValue); // Toggle the switch state

    setFormData((prevData) => ({
      ...prevData,
      vendor: null,
      vendorsList: !!newValue ? freeDrugVendors : regularVendors,
      price: !!newValue ? 0 : prevData.drug?.price
    }));
  };

  const handleSubmit = () => {
    const { drug, lot, qty, expDate, vendor, serial } = formData;

    // after validation
    if (!!drug && !!lot && !!qty && !!expDate && !!vendor && !!serial) {
      try {
        setIsLoading(true);

        const receivedTime = new Date(formData.recDate).toISOString();

        const newProduct = {
          freeDrug: isFreeDrug, // this needs to be from state
          lotNumber: formData.lot,
          ndcNumber: drug.productId,
          quantity: formData.qty,
          receivedTime,
          strength: drug.strength,
          unitOfMeas: drug.unitOfMeas,
          expirationDate: formatDateToAWS(formData.expDate),
          price: formData.price,
          productName: drug.productName,
          vendor: formData.vendor,
          notes: formData.notes,
          serial: formData.serial
        };

        connectToGraphqlAPI({
          graphqlQuery: addProductsToInventory,
          variables: {
            agentId,
            locationId: currentLocation.id,
            products: [newProduct]
          }
        }).then(() => {
          setIsLoading(false);
          setIsSubmitted(true);

          showSuccess(
            `Product was successfully added to the inventory at ${currentLocation?.locationName}`
          );

          // reset form
          handleReset();

          // since API doesn't return newly generated IDs - we have to make another call to get updated inventory
          getLocationInventory(currentLocation.id);

          // dirty hack to reset form
          setTimeout(() => {
            setIsSubmitted(false);
          }, 500);
        });
      } catch (err) {
        showError('Error: the product has not been added to the inventory');
        console.error('AddProductForm::handleSubmit err: ', err);

        setIsLoading(false);
      }
    }
  };

  // Reset the form to initial values
  const handleReset = () => {
    setFormData({
      isFreeDrug: false,
      notes: '',
      recDate: new Date(),
      expDate: null,
      vendor: null,
      vendorsList: regularVendors,
      drug: null,
      price: 0,
      serial: ''
    });
    setIsFreeDrug(false);
  };

  const showPrice = !isFreeDrug && !!formData.price;

  return (
    <Form
      initialValues={formData}
      key={isSubmitted && JSON.stringify(formData)}
      onSubmitClick={handleSubmit}
      render={(formRenderProps) => (
        <FormElement className="k-form">
          <div style={{ textAlign: 'center' }}>
            <ScanBarcode
              callback={(res) => {
                formRenderProps.onChange('lot', {
                  value: res.lot
                });

                formRenderProps.onChange('expDate', {
                  value: res.expDate
                });
              }}
            />
            <hr />
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="pr-2">Free Drug:</label>
              <Switch
                offLabel="NO"
                onLabel="YES"
                onChange={() => {
                  handleSwitchChange();
                  formRenderProps.onChange('vendor', {
                    value: null
                  });
                }}
                checked={isFreeDrug}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Field
                label="Patient Name or other Notes"
                name="notes"
                value={formData.notes}
                component={InputField}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <Field
                label="Date Received"
                component={DatePickerField}
                name="recDate"
                value={formData.recDate}
                min={new Date()}
                onChange={handleChange}
                format="MM/dd/yy"
                validator={validator.recDate.inputValidator}
              />
            </div>
            <div className="col-md-8">
              <Field
                label="Vendor"
                component={DropDownListField}
                name="vendor"
                data={formData.vendorsList}
                value={formData.vendor}
                onChange={handleChange}
                validator={validator.vendor.inputValidator}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className={`col-md-${showPrice ? 9 : 12}`}>
              <Field
                label="Product"
                component={DropDownListField}
                name="drug"
                data={products}
                value={formData.drug}
                textField="productForInventory"
                valueField="selectorValue"
                onChange={handleChange}
                validator={validator.drug.inputValidator}
              />
            </div>
            <div className="col-md-3">
              {showPrice && (
                <>
                  <label className="k-label">Price</label>
                  <Badge text={`$${formData.price}`} type="success" />
                </>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <Field
                label="Serial Number"
                name="serial"
                value={formData.serial}
                component={InputField}
                onChange={handleChange}
                validator={validator.serial.inputValidator}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <Field
                label="Exp. Date"
                name="expDate"
                component={DatePickerField}
                value={formData.expDate}
                min={new Date()}
                onChange={handleChange}
                format="MM/dd/yy"
                validator={validator.recDate.inputValidator}
              />
            </div>
            <div className="col-md-5">
              <Field
                label="LOT #"
                name="lot"
                value={formData.lot}
                component={InputField}
                onChange={handleChange}
                validator={validator.lot.inputValidator}
              />
            </div>
            <div className="col-md-3">
              <Field
                name="qty"
                label="QTY"
                value={formData.qty}
                component={InputField}
                onChange={handleChange}
                validator={validator.qty.inputValidator}
              />
            </div>
          </div>
          <hr />
          <FormButton
            type="submit"
            className="k-button pageButton Blue"
            disabled={!formRenderProps.allowSubmit}
            loading={isLoading}
          >
            Submit
          </FormButton>
        </FormElement>
      )}
    />
  );
};

export default AddProductForm;
