import React from 'react';
import processBarCode from '../Inventory/processBarCode';
import { NotifContext } from '@/context/NotifContext';

const ScanBarcode = ({ callback }) => {
  const { showError } = React.useContext(NotifContext);

  const handleScan = (event) => {
    //const { gtin, lot, expDate } = processBarCode(event.detail.scanCode);
    // testing value...
    //const { gtin, lot, expDate } = processBarCode('~0100350242135016~2110029980267582~17231130~10B5003B02');
    const { gtin, lot, expDate } = processBarCode(
      '01003673861305172114998703667410APTC02A17220331'
    );
    // 04.22.2022 wat retrieve GTIN right aways since will be needed when recovering ProductName and Qty values in future enhancements

    if (gtin === '' || lot === '' || expDate === '') {
      showError('Scanned barcode has invalid format');
    }

    callback({ lot, expDate });
  };

  React.useEffect(() => {
    document.addEventListener('scan', handleScan);

    // cleanup this component
    return () => {
      document.removeEventListener('scan', handleScan);
    };
  }, []);

  return (
    <div className="row f10px">
      <div className="col-md-3">&nbsp;</div>
      <div className="col-md-6">
        <h5>
          <i className="fa-solid fa-barcode-read" />
          &nbsp;Use Scanner
        </h5>
        <h6>&nbsp;Scanner Coming Soon</h6>
        <p style={{ marginBottom: 0 }}>
          Scanner module will be activated. Once you complete scanning - LOT #
          and EXP DATE will appear in the following form.
        </p>
      </div>
      <div className="col-md-3">&nbsp;</div>
    </div>
  );
};
export default ScanBarcode;
